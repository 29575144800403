import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { ContentArticle, RequestConfigs, SignalType } from "../../api";

import { useDeviceType } from "../../context/DeviceContext";
import { useEffect, useState } from "react";
import { routesMap } from "../../constants/routes";
import React from "react";

import { useNavigate } from "react-router-dom";
import { useFormHandling } from "../../hooks/formUtils";
import { SubTitle } from "../eaBuilder/index.style";
import { Icons } from "../../constants/Images";


enum DirectionMap  {
    BUY=1,
    SELL=2,
}



export const Article: React.FC = () => {

    const path = window.location.pathname;
    const id = parseInt(path.split("/").pop() || "0", 10);

    const {handleClick,serverRes} = useFormHandling({ requestType: RequestConfigs.article, params: {articleId:id} })

    const [article,setArticle] = useState<ContentArticle>();

    useEffect(() => {
        handleClick()
    }, [])

    useEffect(() => {
        if(serverRes && serverRes.article){
            setArticle(serverRes.article)
        }
    }, [serverRes])
    
    const {isMobile} = useDeviceType()
    
    const navigate = useNavigate()
        
    const goToMainPage = () => {
        console.log("goToMainPage") 
        navigate(routesMap.news.to)
    };

    if(!article){
        return null
    }
    
      
    
      const apiPath = process.env.REACT_APP_API_ENDPOINT || ""
      
      const imagePath=apiPath + "/client/s3/news/" + article.id + ".jpg";
  
      return (
        <>   
        <Container id="article">
            
                <Head>
                    <BackIcon src={Icons.Arrow} onClick={() => goToMainPage()} alt="back"/>
                    
                    <Title>
                        <MainTitle>{article.title}</MainTitle>
                        <GreyText>{new Date(article.createdOn).toLocaleString()}</GreyText>
                    </Title>
                    <HeadImg src={imagePath} alt="news"/>

                </Head>
                <Body>
                {
                    article.content.map((content,index) => (
                        <Paragraph key={index}>
                            {
                                content.subTitle &&
                                <SubTitle>{content.subTitle}</SubTitle>
                            
                            }
                            {
                                content.text &&
                                <Text>{content.text}</Text>
                            
                            }
                            
                        </Paragraph>
                    ))  
                }
              

                </Body>

                
                
                
            
 
        </Container>   
        
        </>
      );
    };


const Head = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 25px;
`

const Body = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 5px;
`

const HeadImg = styled.img`
box-shadow: 0 0 23px  ${Colors.shadow};
border-radius: 9px;
width: 100%;
height: 250px;
object-fit: cover; 
`

const Title = styled.div`
display: flex;
flex-direction: column;
gap:5px;
align-items: flex-start;

`

const Text = styled.p`
font-size: 14px;
color: ${Colors.text};
`

const ToggleChartButton = styled.button`
margin-bottom: 10px;
background: transparent;
border: none;
color:${Colors.primary};
font-size: 14px;
font-weight: 500;
`;

const BackIcon = styled.img`
box-shadow: 0 0 23px  ${Colors.shadow};
/* border-radius: 50% */
width: 25px;
margin:0;
transform: rotate(180deg);
`

const Container = styled.div`
display: flex;
/* flex-direction: column; */
background-color: ${Colors.whiteBackground};
flex-wrap: wrap;
justify-content:center;
align-items: start;
border: 1px solid ${Colors.fieldBorder};
padding: 20px;
border-radius: 8px;
gap:20px;
width: 100%;
`

const Section = styled.div`
display: flex;
justify-content: space-between;
/* margin-left: 20px;
margin-right: 20px; */
/* width: 100%; */
gap:20px;
/* align-items: center; */
`
const Paragraph = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 100%;
border-radius: 8px;
/* padding: 12px; */

/* align-items: center; */
`

const LeftContainer=styled.div`
display: flex;
align-items: center;
gap:10px;
`

const Symbols = styled.div`
display: flex;
flex-direction: row;
gap: 5px;
`



const Content = styled.div`
display: flex;
flex-direction: column;
gap:5px;
align-items: flex-start;

`





const SymbolName = styled.div`
`


const GreyText = styled.div`
font-size: 14px;
color: ${Colors.text};
`
const MainTitle = styled.div`
display: flex;
justify-content: center;
gap:5px;
color: ${Colors.blackText};
font-size: 25px;
font-weight: 600;
`





const ArticleContainer = styled.div`
flex: 1;
display: flex;
flex-direction: column;
justify-content: center;
gap:20px;
margin-right: 10px;
`

const ChartContainer = styled.div<{$isMobile:boolean}>`
    /* padding: 20px; */
    /* padding-top: 20px; */
    width: ${props=>props.$isMobile?'100%':'50%'};
    cursor: pointer;
`






const DirectionIcon = styled.img`
width: 16px;
`

const StampIcon = styled.img`
position: relative;
/* top: -10px; */
/* right:25px; */
width: 100px;
`



    