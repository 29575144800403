
import { Line } from "../generic/Line";
import { ActionButton,ButtonStyle } from "../buttons/ActionButton";
import { useDeviceType } from "../../context/DeviceContext";
import { Icon,SubscribeContainer,Body,Text,Buttons,DataContainer,SubText, Svg } from "./index.style";
import { AddToCartButton } from "../buttons/AddToCartButton";
import { ProdcutType } from "../buttons/AddToCartButton";
import { BuyNowButton } from "../buttons/BuyNowButton";
import { useFormHandling } from "../../hooks/formUtils";
import { MembershipOption, RequestConfigs } from "../../api";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { max, set } from "date-fns";

interface SubscribeDataProps{
  icon:any
  text:string
  subText:any
}
const mostPopularIndex = 1
export const SubscribeData = ({icon,text,subText}:SubscribeDataProps) => {

  const {isMobile} = useDeviceType()
  const [membershipOptions,setMembershipOptions] = useState<MembershipOption[]>()
  const [pricing,setPricing] = useState<number[]>()
  const [maxPrice,setMaxPrice] = useState<number>()
  const [selectedIndex,setSelectedIndex] = useState<number>(mostPopularIndex)

  

  const {handleClick,serverRes} = useFormHandling({requestType:RequestConfigs.getMembershipOffers})
  
  useEffect(() => {
    handleClick()
  }, [])

  useEffect(() => {
    if(serverRes?.membershipsOptions){
      const membershipsOptions = serverRes.membershipsOptions.sort((a,b)=>a.firstCycleDays-b.firstCycleDays)
      setMembershipOptions(membershipsOptions)
      setPricing(membershipsOptions.map(option=>option.price/option.firstCycleDays*30))
      setMaxPrice(Math.max(...membershipsOptions.map(option=>option.price/option.firstCycleDays*30)))
    }
  }, [serverRes])

  return (
    <DataContainer id="data-container" $isMobile={isMobile}>        
      <Body>
        <SubscribeContainer>
          <Svg as={icon} width="100px" />
          <Text>{text}</Text>
          <Offers>
            { pricing && maxPrice &&
              membershipOptions?.map((option,index)=>(
               <OfferOption key={option.id} option={option} pricing={pricing} maxPrice={maxPrice} index={index} selectedIndex={selectedIndex} onSelect={setSelectedIndex}/>
              ))  
            }
            
          </Offers>
          <SubText>{subText}</SubText>
          <Line/>
          <Buttons>
            <AddToCartButton itemId={membershipOptions?.[selectedIndex].id || 1} productType={ProdcutType.memberProgram}/>
            <BuyNowButton productType={ProdcutType.memberProgram} itemId={membershipOptions?.[selectedIndex].id || 1} actionName="Subscribe Now"/>
          </Buttons>    
        </SubscribeContainer>
      </Body>
  </DataContainer>
  );
};

interface OfferOptionProps{
  option:MembershipOption
  pricing:number[]
  maxPrice:number
  index:number
  selectedIndex:number
  onSelect(index:number):void
}

const OfferOption = ({option,pricing,maxPrice,onSelect,selectedIndex,index}:OfferOptionProps)=>{

  return (
    <Offer $isSelected={selectedIndex===index} onClick={()=>onSelect(index)}>
      {
        index===mostPopularIndex && 
        <Band>Most Popular</Band>
      }
      <Period>{option.currency}{pricing?.[index].toFixed(2)} / Mo</Period>
      <Text>{option.name}</Text>
      <Save>{(pricing?.[index] && maxPrice &&  maxPrice>pricing[index])?"Save "+(100*(1-pricing?.[index]/maxPrice)).toFixed(2)+"%":""}</Save>
      <Text style={{fontSize:"12px",textAlign:"center"}}>Billed every {option.firstCycleDays} days</Text>
      
  </Offer>
  )
  
}


const Band = styled.div`
  position: absolute;
  background-color: ${Colors.primary};
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: ${Colors.whiteBackground};
  padding: 5px 10px;
  top: 60px;
  right: -20px; /* Adjust this value to make the band wider */
  width: 90px; /* Adjust this value to make the band wider */
  transform: rotate(45deg);
  transform-origin: top right;
`;
const Offers = styled.div`
gap: 10px;
display: flex;

  `

const Offer = styled.div<{$isSelected:boolean}>`
position: relative;
display: flex;
flex-direction: column;
text-align: center;
border:${props=>props.$isSelected?"3px":"1px"} solid ${props=>props.$isSelected?Colors.primary:Colors.fieldBorder} ;
border-radius: 5px;
padding: 10px;
max-width: 220px;
box-sizing: border-box;
overflow: hidden;
cursor: pointer;
`


const Period = styled.p`
font-size: 16px;
font-weight: 700;
text-align: center;
`

const Save = styled.p`
font-size: 16px;
font-weight: 700;
text-align: center;
color: ${Colors.primary};
`
