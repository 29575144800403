import React from "react";
import { ImageContainer } from "./index.style";

interface ImageProps {
  paddingtop?: number;
  width?: number | string;
  height?: number | string;
  src: string;
  to?: string
}

export const Image: React.FC<ImageProps> = (props) => {
  const { paddingtop, width, height, src,to } = props;

  const handleClick = () => {
    if(to){ 
      window.open(to, '_blank')
    }
  }
  return (
    <ImageContainer
    onClick={handleClick}
      paddingtop={paddingtop}
      width={width}
      height={height}
      src={src}
    />
  );
};
