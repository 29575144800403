import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { ContentArticle, SignalType } from "../../api";
import { Icons } from "../../constants/Images";
import { useDeviceType } from "../../context/DeviceContext";
import { useState } from "react";
import { routesMap } from "../../constants/routes";
import React from "react";
import { DataProps } from "../data/DataArea";
import { useDictionary } from "../../context/DictionaryContext";
import { ImageBrowse } from '../generic/ImageBrowse';
import { useNavigate } from "react-router-dom";


enum DirectionMap  {
    BUY=1,
    SELL=2,
}



export const NewsBox: React.FC<DataProps>= ({data}) => {
    
    const {isMobile} = useDeviceType()
    
    const [isChartVisible, setChartVisibility] = useState(isMobile?false:true);
    const newsData = data as ContentArticle;
    
      const navigate = useNavigate()
    
      const openArticle = (id:number) => {
        navigate(routesMap.news.to+"/"+id.toString())
      };

      const apiPath = process.env.REACT_APP_API_ENDPOINT || ""
      
      const imagePath=apiPath + "/client/s3/news/" + newsData.id + ".jpg";
  
      return (
        <>   
        <Container id="main-sig">
            <SignalContainer id="sig-cont">
                <Section>
                    <LeftContainer>
                        
                        <Title onClick={()=>openArticle(newsData.id)}>
                            <MainTitle>                                
                                <SymbolName>{newsData.title}</SymbolName>
                            </MainTitle>
                        
                            
                    
                        </Title>
                    </LeftContainer>
                    
                    
                </Section> 

                <Section>
                    <LeftContainer>
                
                        <Content>
                             <GreyText>{new Date(newsData.createdOn).toLocaleString()}</GreyText>
                            <Symbols>
                                {
                                    newsData.relatedAssetsImages.length>0 &&
                                    newsData.relatedAssetsImages.map((path,index) => (
                                        <SymbolIcon key={index} src={apiPath + path} />
                                    ))
                                }

                            </Symbols>
                        
                        
                        
                            
                            
                            
                        
                            
                    
                        </Content>
                    </LeftContainer>
                
                    
                </Section> 

             
                
                
            </SignalContainer>
            <></>
            <ChartContainer $isMobile={isMobile}>
                {
                isMobile && 
                <ToggleChartButton onClick={() => setChartVisibility(!isChartVisible)}>
                    {isChartVisible ? " < Hide Chart" : "Show Chart >"}
                </ToggleChartButton>
                }
            
         
            <Chart src={imagePath} onClick={()=>openArticle(newsData.id)} />
  
        </ChartContainer>
            
            
        </Container>   
        
        </>
      );
    };


const ToggleChartButton = styled.button`
margin-bottom: 10px;
background: transparent;
border: none;
color:${Colors.primary};
font-size: 14px;
font-weight: 500;
`;

const Container = styled.div`
display: flex;
/* flex-direction: column; */
background-color: ${Colors.whiteBackground};
flex-wrap: wrap;
justify-content:center;
align-items: start;
border: 1px solid ${Colors.fieldBorder};
padding: 20px;
border-radius: 8px;
gap:20px;
`

const Section = styled.div`
display: flex;
justify-content: space-between;
/* margin-left: 20px;
margin-right: 20px; */
/* width: 100%; */
gap:20px;
/* align-items: center; */
`
const Box = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 40%;
border-radius: 8px;
padding: 12px;
background-color:${Colors.messageBox};
/* align-items: center; */
`

const LeftContainer=styled.div`
display: flex;
align-items: center;
gap:10px;
`

const Symbols = styled.div`
display: flex;
flex-direction: row;
gap: 5px;
`

const Title = styled.div`
display: flex;
flex-direction: column;
gap:5px;
align-items: flex-start;
cursor: pointer;

`

const Content = styled.div`
display: flex;
flex-direction: column;
gap:5px;
align-items: flex-start;

`





const SymbolName = styled.div`
`


const GreyText = styled.div`
font-size: 14px;
color: ${Colors.text};
`
const MainTitle = styled.div`
display: flex;
justify-content: center;
gap:5px;
color: ${Colors.blackText};
font-size: 18px;
font-weight: 600;
`





const SignalContainer = styled.div`
flex: 1;
display: flex;
flex-direction: column;
justify-content: center;
gap:20px;
margin-right: 10px;
`

const ChartContainer = styled.div<{$isMobile:boolean}>`
    /* padding: 20px; */
    /* padding-top: 20px; */
    width: ${props=>props.$isMobile?'100%':'50%'};
    cursor: pointer;
`


const Chart = styled.img`
box-shadow: 0 0 23px  ${Colors.shadow};
border-radius: 9px;
width: 100%;
height: auto; 
object-fit: contain; 

`

const SymbolIcon = styled.img`
box-shadow: 0 0 23px  ${Colors.shadow};
/* border-radius: 50% */
width: 25px;
margin:0;
`

const DirectionIcon = styled.img`
width: 16px;
`

const StampIcon = styled.img`
position: relative;
/* top: -10px; */
/* right:25px; */
width: 100px;
`



    