import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { useDeviceType } from "../../context/DeviceContext";
import React from "react";
import { FormComponent } from "../form";
import Joi from "joi";
import { ActionButton } from "../buttons/ActionButton";
import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";
import { FieldConfig,TextFieldType,FieldType } from "../fields";




const fields:FieldConfig[] = [
    {
        type:FieldType.text,
        name:"promoCode",
        label:"Promo Code",
        joiSchema:Joi.string().max(45).required(),
        typeOptions:{type:TextFieldType.text}
      },
]


export const PromoForm: React.FC= () => {
    const {handleInputChange,handleClick,serverRes}=useFormHandling({requestType:RequestConfigs.applyPromo})
    const {isMobile} = useDeviceType()
    

      return (
        <>   
        <Box $isMobile={isMobile}>
         
            <Content>
                <Left>
                <FormComponent align="flex-start" fields={fields} title="Promo Code" onChange={handleInputChange} serverRes={serverRes}/>
                </Left>
                <Right>
                    <ActionButton onClick={handleClick} label="Apply" width="100%" height="48px" fontSize="16px" />
                </Right>
            </Content>
            
            
        </Box>   
        
        </>
      );
    };

    const Box = styled.div<{$isMobile:boolean}>`
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    background-color: ${Colors.whiteBackground};
    border:${prop=>prop.$isMobile?'none' : '1px solid ' + Colors.fieldBorder} ;
    padding:${prop=>prop.$isMobile?'none' : '20px'} ;
    border-radius: 8px;
    `

    const Content = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    gap:10px;
    width: 100%;
    box-sizing: border-box;
    padding:10px;
    `

   

    const Left = styled.div`
    flex:1;
    align-self: flex-end;
    `

    const Right = styled.div`
    /* margin-top: -11px; */
    margin-bottom: 20px;
    width: 80px;
    align-self: flex-end;
    `

