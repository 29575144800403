import React from 'react'
import { DefaultPage } from '../components/Layout/DefaultPage'
import { News } from '../components/news'


export const NewsPage: React.FC = () => {
    return (
        <DefaultPage>
            <News />
        </DefaultPage>
    )
}