import React, { useState } from 'react'

import styled from 'styled-components'
import { FullPage } from '../components/Layout/FullPage'
import { Colors } from '../theme/colors'
import { Backgrounds } from '../constants/Images'
import { Field } from '../components/fields'
import { FieldConfig, FieldType } from '../components/fields'
import Joi from 'joi'
import { TextFieldType } from '../components/fields/TextField'
import { BoxProps,Box } from '../components/faq/Box'
import { ActionButton } from '../components/buttons/ActionButton'
import { useDeviceType } from '../context/DeviceContext'


const field:FieldConfig = {
    type: FieldType.text,
    name: `search`,
    label: `Search`,
    joiSchema: Joi.string().allow(null, ''),
    typeOptions:{type:TextFieldType.search}
  }

  const faq:BoxProps[] = [
    {
      question: `What products and services does ${process.env.REACT_APP_BRAND_NAME} offer?`,
      answer: `${process.env.REACT_APP_BRAND_NAME} offers three main categories of products and services: Trading Robots, which are automated trading software designed to execute trades based on predefined criteria;  and Trading Signals, which are alerts and signals that indicate potential trading opportunities based on market analysis.`
    },
    {
      question: `How do I create an account on www.${process.env.REACT_APP_DOMAIN_NAME}?`,
      answer: `To create an account, visit www.${process.env.REACT_APP_DOMAIN_NAME} and click on the 'Sign Up' button. Follow the prompts to enter your personal information and complete the registration process. You will receive a confirmation email once your account is successfully created.`
    },
    {
      question: `What information do I need to provide to register?`,
      answer: `During registration, you will need to provide your full name, email address, a valid government-issued identification, and your residential address. This information is required to comply with our Anti-Money Laundering (AML) policy and ensure the security of our platform.`
    },
    {
      question: `What payment methods are accepted?`,
      answer: `${process.env.REACT_APP_BRAND_NAME} accepts various payment methods, including credit/debit cards, bank transfers, and popular online payment systems. Detailed information about payment options is available during the checkout process.`
    },
    {
      question: `How do I cancel my subscription?`,
      answer: `To cancel your subscription, log in to your account on www.${process.env.REACT_APP_DOMAIN_NAME}, go to the 'Account Settings' or 'Subscriptions' section, and follow the instructions for cancellation. You can also contact our customer support team for assistance.`
    },
    {
      question: `Do you guarantee profitability through your services?`,
      answer: `No, ${process.env.REACT_APP_BRAND_NAME} does not guarantee profitability through any of its services. Trading involves significant risk, and there is always the potential for loss. Our trading robots, and signals are designed to assist and educate traders, but they do not eliminate the risks associated with trading. It is important for traders to understand these risks and trade responsibly.`
    },
    {
      question: `What measures can I take to manage trading risks?`,
      answer: `To manage trading risks, it is important to educate yourself thoroughly about trading strategies, use risk management tools such as stop-loss orders, diversify your investments, and only trade with money you can afford to lose. ${process.env.REACT_APP_BRAND_NAME} provides educational resources and tools to help you make informed decisions, but it is ultimately your responsibility to manage your trading risks.`
    },
    {
      question: `How does ${process.env.REACT_APP_BRAND_NAME} protect my personal information?`,
      answer: `${process.env.REACT_APP_BRAND_NAME} takes the privacy and security of your personal information seriously. We use advanced encryption technologies and adhere to strict data protection regulations to ensure your information is safe. For more details, please refer to our Privacy Policy on www.${process.env.REACT_APP_DOMAIN_NAME}.`
    },
    {
      question: `What measures are in place to prevent fraud and money laundering?`,
      answer: `${process.env.REACT_APP_BRAND_NAME} has implemented a comprehensive Anti-Money Laundering (AML) Policy that includes customer due diligence, ongoing transaction monitoring, and regular risk assessments. We also provide training to our staff to recognize and report suspicious activities. For more information, please refer to our AML Policy on www.${process.env.REACT_APP_DOMAIN_NAME}.`
    },
    {
      question: `How can I contact customer support?`,
      answer: `You can contact our customer support team by visiting the 'Contact Us' page on www.${process.env.REACT_APP_DOMAIN_NAME}. We offer multiple contact options, including email, phone, and live chat support. Additionally, our website features an online chat option for real-time assistance. Our team is available to assist you with any questions or issues you may have.`
    },
    {
      question: `Where can I find additional resources and tutorials?`,
      answer: `${process.env.REACT_APP_BRAND_NAME} provides a variety of educational resources and tutorials on our website. Visit the 'Resources' or 'Education' section to access articles, videos, webinars, and other materials designed to help you improve your trading skills and knowledge.`
    },
    {
      question: `What is ${process.env.REACT_APP_BRAND_NAME}’s refund policy?`,
      answer: `At ${process.env.REACT_APP_BRAND_NAME}, customer satisfaction is our priority. However, due to the digital nature of our products and services, we generally do not offer refunds. Exceptions may be made in certain circumstances, such as if you experience technical issues that we are unable to resolve. To request a refund, please contact our customer support team with your order details and a description of the issue. All refund requests will be reviewed on a case-by-case basis.`
    }
  ];
  


export const FaqPage: React.FC = () => {
    const [filteredFaq,setFilteredFaq] = useState<BoxProps[]>(faq)
    const {isMobile} = useDeviceType()

    const searchChange = (name:string,value:string)=>{
        if(value===``)
            setFilteredFaq(faq)
        else
            setFilteredFaq(faq.filter(item=>item.question.includes(value) || item.answer.includes(value)))
    }

    const handleEmailClick = () => {
        window.location.href = `mailto:support@${process.env.REACT_APP_DOMAIN_NAME}`;
      };
    return (
        <FullPage>
            <Container>
            <Head $isMobile={isMobile}>
            
            <MainTitle>
                How can we help you?
            </MainTitle>
            <Search $isMobile={isMobile}>
                <Field fieldConfig={field} onChange={searchChange}/>
            </Search>

            
                
            
            </Head>
            <FirstBox $isMobile={isMobile}>
                <Title>
                What is ${process.env.REACT_APP_BRAND_NAME}?
                </Title>
                <Text>
                ${process.env.REACT_APP_BRAND_NAME} is a company specializing in providing trading robots, and trading signals to help traders optimize their strategies and improve their trading outcomes. Our website, www.{process.env.REACT_APP_DOMAIN_NAME}, offers a variety of tools and educational resources for both beginner and experienced traders.
                </Text>
                
            </FirstBox>
            {filteredFaq.map((item, index) => (
                
                <Box question={item.question} answer={item.answer} key={index}/>
            
            ))}
            <ContactUs>
                <SectionTitle>
                    Can’t find what you are looking for?
                </SectionTitle>
                <ActionButton label='Email Us' height='48px' fontSize='16px' width='150px' onClick={handleEmailClick}/>
            </ContactUs>
            
    
            
            </Container>
           
            
            
            
        </FullPage>
    )
}


const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
background-image: url(${Backgrounds.MainBackground});
background-size: cover;
background-position: center; // Ensure the background is centered
`
const MainTitle = styled.h3`
color:${Colors.whiteBackground};
font-size: 36px;
font-weight: 600;
margin-top: -30px;
`

const ContactUs = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin:75px;
`

const SectionTitle = styled.h3`
color:${Colors.text};
font-size: 36px;
font-weight: 500;
/* margin-top: -30px; */
`

const Head = styled.div<{$isMobile:boolean}>`
display: flex;
background-color:${Colors.primary};
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
height: 425px;
border-radius: ${props=>props.$isMobile?`0px 0px 75px 75px`:`0px 0px 400px 400px`};
background-image: url(${Backgrounds.WhiteLines});
background-size: cover;
background-position: center;
box-shadow:   0px 4px 4px rgba(0, 0, 0, 0.5);
`

const Search = styled.div<{$isMobile:boolean}>`
width: ${props=>props.$isMobile?`80%`:`40%`};
`


const FirstBox = styled.div<{$isMobile:boolean}>`
box-sizing: border-box;
width: ${props=>props.$isMobile?`80%`:`60%`};
background-color: ${Colors.whiteBackground};
margin-top: -60px;
border-radius: 8px;
box-shadow:   0px 4px 4px ${Colors.darkShadow};
border: 1px solid ${Colors.fieldBorder};
`
const Title = styled.h3`
padding: 20px 10px 0px 20px;
margin: 0;
color:${Colors.text};
`

const Text = styled.p`
padding: 0px 10px 0px 20px;
font-size: 16px;
color:${Colors.text};
`

