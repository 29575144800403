import React,{useLayoutEffect} from 'react'
import { BrowserRouter, Route, Routes,useLocation } from "react-router-dom"

import { CoursesPage } from './Courses'
import { CoursesMarketPage } from './CoursesMarket'
import { ExpertsPage } from './Experts'
import { ExpertsMarketPage } from './ExpertsMarket'
import { LiveSignalsPage } from './LiveSignals'
import { LoginPage } from './Login'
import { MyCoursesPage } from './MyCourses'
import { MyExpertsPage } from './MyExperts'
import { SignalsPage } from './Signals'
import { SignalsHistoryPage } from './signalsHistory'
import { ForgotPasswordPage } from './ForgotPassword'
import { SignupPage } from './Signup'
import { routesMap ,accountRoutesMap,paymentsRoutesMap} from '../constants/routes'
import { HomepagePage } from './Homepage'
import { LogoutPage } from './Logout'
import { CartPage } from './Cart'
import { PayPage } from './Pay'
import { ForgotPasswordThankUPage } from './ForgotPasswordThankU'
import { PaymentThankUPage } from './PaymentThankU'
import { TermsPage } from './Terms'
import { PrivacyPolicyPage } from './PrivacyPolicy'
import { AccountPage } from './Account'
import { Process3DPage } from './Process3D'
import { FingerPrintPage } from './FingerPrint'
import { ChallengePage } from './Challenge'
import { AmlPage } from './AML'
import { FaqPage } from './FAQ'
import { EaBuilderPage } from './EaBuilder'
import { MyBuildsPage } from './MyBuilds'
import { NewBuildPage } from './NewBuild'
import { Process3DNativePage } from './Process3DNative'
import { DeleteAccountPage } from './DeleteAccount'
import { NewsPage } from './NewsPage'
import { ArticlePage } from './ArticlePage'


interface WrapperProps{
    children:React.ReactElement
}
const Wrapper:React.FC<WrapperProps> = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  } 


const AppRouter: React.FC = () => {
    return (
        <BrowserRouter>
        <Wrapper>
        <Routes>
                <Route element={<HomepagePage />} path={routesMap.homePage.to}></Route>
                <Route element={<CoursesPage />} path={routesMap.courses.to}></Route>
                <Route element={<CoursesMarketPage />} path={routesMap.coursesMarket.to}></Route>
                <Route element={<MyCoursesPage />} path={routesMap.myCourses.to}></Route>
                <Route element={<ExpertsPage />} path={routesMap.experts.to}></Route>
                <Route element={<ExpertsMarketPage />} path={routesMap.expertsMarket.to}></Route>
                <Route element={<MyExpertsPage />} path={routesMap.myExperts.to}></Route>
                <Route element={<LiveSignalsPage />} path={routesMap.liveSignals.to}></Route>
                <Route element={<NewsPage />} path={routesMap.news.to}></Route>
                <Route element={<ArticlePage />} path={`${routesMap.news.to}/:id`}></Route>
                
                
                <Route element={<SignalsPage />} path={routesMap.signals.to}></Route>
                <Route element={<SignalsHistoryPage />} path={routesMap.signalsHistory.to}></Route>
        
                <Route element={<LoginPage />} path={routesMap.login.to}></Route>
                <Route element={<DeleteAccountPage />} path={routesMap.deleteAccount.to}></Route>
                <Route element={<ForgotPasswordPage />} path={routesMap.forgotPassword.to}></Route>
                <Route element={<SignupPage />} path={routesMap.signup.to}></Route>
                <Route element={<LogoutPage />} path={accountRoutesMap.logout.to}></Route>
                <Route element={<CartPage />} path={routesMap.cart.to}></Route>
                <Route element={<PayPage />} path={accountRoutesMap.pay.to}></Route>
                <Route element={<ForgotPasswordThankUPage />} path={routesMap.thankuPage.to}></Route>
                <Route element={<PaymentThankUPage />} path={accountRoutesMap.payThankuPage.to}></Route>
                <Route element={<TermsPage />} path={routesMap.tc.to}></Route>
                <Route element={<PrivacyPolicyPage />} path={routesMap.privacyPolicy.to}></Route>
                <Route element={<AccountPage />} path={accountRoutesMap.account.to}></Route>
                <Route element={<AccountPage />} path={accountRoutesMap.resetPassword.to}></Route>
                <Route element={<AccountPage />} path={accountRoutesMap.personalInfo.to}></Route>
                <Route element={<AccountPage />} path={accountRoutesMap.signalsPreference.to}></Route>
                <Route element={<AccountPage />} path={accountRoutesMap.marketingPreference.to}></Route>
                <Route element={<AccountPage />} path={accountRoutesMap.membershipProgram.to}></Route>
                <Route element={<Process3DPage />} path={paymentsRoutesMap.auth.to}></Route>
                <Route element={<Process3DNativePage />} path={paymentsRoutesMap.native3d.to}></Route>
                <Route element={<FingerPrintPage />} path={paymentsRoutesMap.fingerPrint.to}></Route>
                <Route element={<ChallengePage />} path={paymentsRoutesMap.challenge.to}></Route>
                <Route element={<AmlPage />} path={routesMap.aml.to}></Route>
                <Route element={<FaqPage />} path={routesMap.faq.to}></Route>
                <Route element={<EaBuilderPage />} path={routesMap.eaBuilder.to}></Route>
                <Route element={<MyBuildsPage />} path={routesMap.myEaBuilds.to}></Route>
                <Route element={<NewBuildPage />} path={routesMap.newEaBuild.to}></Route>

                
            </Routes>
        </Wrapper>
            
        </BrowserRouter>
    )
}

export default AppRouter