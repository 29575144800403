import React from 'react'
import { DefaultPage } from '../components/Layout/DefaultPage'
import { News } from '../components/news'
import { Article } from '../components/news/article'


export const ArticlePage: React.FC = () => {
    return (
        <DefaultPage>
            <Article />
        </DefaultPage>
    )
}