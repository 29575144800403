import { set } from 'date-fns';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface MarketingContextProps {
    affId?: string;
    [key: string]: any;
}

const MarketingContext = createContext<MarketingContextProps | undefined>(undefined);

interface MarketingProviderProps {
    children: React.ReactNode;
}


export const MarketingProvider: React.FC<MarketingProviderProps> = ({ children }) => {
    const [params, setParams] = useState<MarketingContextProps>({});

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const tmp:any = {}
        urlParams.forEach((value, key) => {
            tmp[key] = value;
        })
    
        const p = localStorage.getItem('params') || "{}";
        const prasedParams = JSON.parse(p);
        setParams((prev: MarketingContextProps) => {
            const newD = { ...prev, ...prasedParams, ...tmp };
            localStorage.setItem('params', JSON.stringify(newD));
            return newD;
        });
    }, []);

    return (
        <MarketingContext.Provider value={params}>
            {children}
        </MarketingContext.Provider>
    );
};

export const useMarketing = (): MarketingContextProps => {
    const context = useContext(MarketingContext);
    if (context === undefined) {
        throw new Error('useMarketing must be used within a MarketingProvider');
    }
    return context;
};