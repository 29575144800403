import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Icons } from '../../constants/Images';
import { Colors } from '../../theme/colors';
import { auth } from '../../modules/fcm';
import { GoogleAuthProvider,signInWithPopup, OAuthProvider } from "firebase/auth";
import { useFormHandling } from '../../hooks/formUtils';
import { RequestConfigs } from '../../api';
import { useState } from 'react';
import { routesMap } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { useMarketing } from '../../context/MarketingContext';



// SocialPanel component to house the social login options
export const SocialPanel: React.FC = () => {
  const {affId} = useMarketing()
  const navigate = useNavigate()
  const [formData, setFormData] = useState<any>(null);
  const {handleClick,serverRes} = useFormHandling({requestType:RequestConfigs.socialAuth,data:formData})
  // Define the onClick handlers for the social buttons
  const handleGoogleLogin =async () => {
    
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider)
      const user =  result.user;
      const data = {
        name:user.displayName,
        emailAddress:user.email,
        photoURL:user.photoURL,
        firebaseId:user.uid,
        allowTerms:true,
        affId:affId
      }
      setFormData(data)
      
    } catch (e) {
        console.log(e,"error")
    }
  
  };

  const handleAppleLogin = async () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    try {
      const result = await signInWithPopup(auth, provider)
      const user = result.user;

      // Apple credential
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential?.accessToken;
      const idToken = credential?.idToken;
      

    } catch (e) {
        console.log(e)
    }


    // Implement login logic here
  };

  useEffect(() => {
    if(formData!==null)
      handleClick()
}, [formData]);


useEffect(() => {
  if(serverRes!==null && serverRes.succeeded)
    navigate(routesMap.homePage.to)
    
}, [serverRes]);

  

  return (
    <SocialLoginContainer>
        <SocialButton onClick={handleGoogleLogin}>
            <Icon src={Icons.Google}/>
        </SocialButton>
        <SocialButton onClick={handleAppleLogin}>
            <Icon src={Icons.Apple}/>
        </SocialButton>
    </SocialLoginContainer>
  );
};


// Styled container for the social login buttons
const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Icon = styled.img`
width: 32px;
width: 32px;
`

// Styled button for social logins
const SocialButton = styled.button`
  
  border: 1px solid ${Colors.fieldBorder};;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 56px;
  height: 56px;
  cursor: pointer;
`;
